<template>
  <div class="table-component notif">
    <div class="table-vidjet">
      <table>
        <thead>
          <colgroup width="60%"></colgroup>
          <colgroup width="20%"></colgroup>
          <colgroup width="20%"></colgroup>
          <tr>
            <th v-for="th in optionsThead" :key="th.id">{{ th.title }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="option in options" :key="option.id" @click="onClick(option.id)">
            <td width="60%">
              <a :href="currentUrl(option)">{{ option.description.title }}</a>
            </td>
            <td width="20%" v-if="option.created">{{ $momentFormat(option.created, 'DD MMMM YYYY') }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import Api from '@/common/api/notification';
  export default {
    name: 'Widget12',
    components: {},
    methods: {
      loadItems() {
        Api.searchUnread({ searchNew: false }).then((response) => {
          this.$nextTick(() => {
            console.log(response.data);
            this.options = response.data;
          });
        });
      },
      currentUrl(currentNotification) {
        if (currentNotification) {
          const route = this.$router.resolve({ name: 'home' });
          const absoluteURL = new URL(route.href, window.location.href).href;
          return absoluteURL + currentNotification.description?.catalog?.baseUrl + currentNotification.entityId;
        } else return '';
      },
      onClick(id) {
        Api.markAsRead(id).then(() => {
          this.loadItems();
        });
      },
    },
    data() {
      return {
        options: [],
        optionsThead: [
          { title: 'Категория', id: 1 },
          { title: 'Дата', id: 2 },
        ],
      };
    },
    created() {
      this.loadItems();
    },
  };
</script>

<style lang="scss">
  @import '@/components/widgets/inners/tables/tableWidgets.scss';
</style>
